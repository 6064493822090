import React from 'react';
import PropTypes from 'prop-types';
import ResolveConflictsTable from './resolve-conflicts-table.component.js';
import { sortConflicts } from './resolve-conflict-modal.helper.js';
import styles from './resolve-table.styles.css';

export default class ResolveConflictModal extends React.Component {

  static propTypes = {
    closeDialog: PropTypes.func.isRequired,
    conflicts: PropTypes.array.isRequired,
    crmColumns: PropTypes.array.isRequired,
    resolveConflicts: PropTypes.func.isRequired,
    isBusiness: PropTypes.oneOfType([PropTypes.bool, PropTypes.string])
  };

  constructor(props) {
    super(props);

    this.state = {
      isValid: false,
      mergeChoices: {},
      conflicts: sortConflicts(props.crmColumns, props.conflicts)
    };
  }

  componentDidMount() {
    //Skip modal if there is nothing to resolve
    if (this.props.conflicts.length === 0) {
      this.merge(this.state.mergeChoices);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    this.isValidCheck();
  }

  render() {
    const {closeDialog, isBusiness} = this.props;
    const {isValid, mergeChoices, conflicts} = this.state;
    if (this.props.conflicts.length === 0) return null
    return <div className="cps-modal">
      <div className="cps-modal__screen"></div>
      <div className={`cps-modal__dialog cps-card cps-card__height-3 cps-body-sm ${styles.resolveCard}`}>
        <div className="cps-card__header cps-subheader-sm"><span>Resolve fields</span>
          <a onClick={closeDialog} className="cps-modal__dialog__close cps-icon cps-icon-close"></a>
        </div>
        <div className="cps-card__body">
          Some fields cannot contain different content when merged. Please select which content you would like merged with this contact.
        </div>
        <div className="cps-card__body">
          <ResolveConflictsTable conflicts={conflicts} isBusiness={isBusiness} selectionChange={this.selectionChange} />
        </div>
        <div className="cps-modal__dialog__actions">
          <button onClick={this.merge.bind(this, mergeChoices)} className={`cps-btn +primary ${isValid ? null : '+disabled'}`}>Merge</button>
          <a onClick={closeDialog} className="cps-link">Keep separate</a>
        </div>
      </div>
    </div>
  }

  isValidCheck = () => {
    const isValid = this.isValid();
    if (this.state.isValid !== isValid) {
      this.setState({ isValid });
    }
  }

  isValid = () => {
    return Object.keys(this.state.mergeChoices).length === this.props.conflicts.length;
  }

  selectionChange = (conflictName, event) => {
    const { mergeChoices } = this.state;
    mergeChoices[conflictName] = event.target.value;
    this.setState({ mergeChoices });
  }

  merge = merge_choices => {
    this.props.resolveConflicts(merge_choices);
    this.props.closeDialog();
  }
}
