exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, "\n.src-modals-resolve-table-styles__resolveTable {\n  background: rgba(0,150,230,0.05);\n  color: #0096E6;\n  border: 2px solid #ECF4F8;\n  overflow: auto;\n}\n\n.src-modals-resolve-table-styles__justifyLeft {\n  justify-content: flex-start;\n}\n\n.src-modals-resolve-table-styles__resolveTableHeader {\n  border-bottom: 2px solid #ECF4F8;\n}\n\n.src-modals-resolve-table-styles__resolveTableHeaderText {\n  font-weight: normal;\n  text-align: left;\n  padding: 8px;\n  border: inherit;\n}\n\n.src-modals-resolve-table-styles__resolveTableText {\n  font-weight: bold;\n}\n\n.src-modals-resolve-table-styles__radioLabel {\n  margin: 0px;\n  white-space: nowrap;\n  float: left;\n  clear: both;\n}\n\n.src-modals-resolve-table-styles__resolveCard {\n  width: 70rem !important;\n  left: 44%;\n}\n\n.src-modals-resolve-table-styles__radioLabelSpan {\n  padding: .4rem 2.4rem !important;\n}", ""]);

// exports
exports.locals = {
	"resolveTable": "src-modals-resolve-table-styles__resolveTable",
	"justifyLeft": "src-modals-resolve-table-styles__justifyLeft",
	"resolveTableHeader": "src-modals-resolve-table-styles__resolveTableHeader",
	"resolveTableHeaderText": "src-modals-resolve-table-styles__resolveTableHeaderText",
	"resolveTableText": "src-modals-resolve-table-styles__resolveTableText",
	"radioLabel": "src-modals-resolve-table-styles__radioLabel",
	"resolveCard": "src-modals-resolve-table-styles__resolveCard",
	"radioLabelSpan": "src-modals-resolve-table-styles__radioLabelSpan"
};