export default function getTitleBarContent(displayState) {

  const defaultReturn = {
    title: "Canopy data de-duplication",
    subtitle: "Thank you for using this utility!",
  };

  const titles = {
    dedupe: {
      title: 'Possible duplicates found',
      subtitle: "Here's a list of possible duplicate contacts or tax identification numbers from your recent import. To complete the import process, review and resolve each potential duplicate contact.",
    },

    complete: {
      title: "You're all done",
      subtitle: 'It looks like all your duplicates have been resolved.',
    },
  };

  return titles[displayState] || defaultReturn;
}
