exports = module.exports = require("../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-dedupe-modal-styles__modalContent {\n  position: fixed;\n  top: 0;\n  left: 0;\n  min-width: 500px;\n  width: 100%;\n  height: 100%;\n  background-color: #f4f5f8;\n  display: flex;\n  flex-direction: column;\n  z-index: 10000;\n  overflow: hidden;\n}\n\n.src-dedupe-modal-styles__mainContent {\n  padding: 32px 24px;\n  height: calc(100% - 112px);\n  overflow: auto;\n}\n\n.src-dedupe-modal-styles__fullHeight {\n  display: flex;\n  height: 100%;\n  width: 100%;\n}\n", ""]);

// exports
exports.locals = {
	"modalContent": "src-dedupe-modal-styles__modalContent",
	"mainContent": "src-dedupe-modal-styles__mainContent",
	"fullHeight": "src-dedupe-modal-styles__fullHeight"
};