import canopyUrls from 'canopy-urls!sofe';
import { fetchAsObservable } from 'fetcher!sofe';

export function getCRMColumns() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/fields`);
}

export function getLatestImports() {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports`);
}

export function getImportDetails(import_id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports/${import_id}`);
}

export function getDupes(import_id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports/${import_id}/dupe`);
}

export function putResolutions(import_id, resolutions) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports/${import_id}/dupe`, {
    method: 'PUT',
    body: `${JSON.stringify({ dedupe: { import_resolutions: resolutions } })}`
  });
}

export function getInvalidRows(import_id) {
  return fetchAsObservable(`${canopyUrls.getWorkflowUrl()}/api/import/imports/${import_id}/invalid_rows`);
}
