import React from 'react';
import PropTypes from 'prop-types';
import canopyUrls from 'canopy-urls!sofe';

export default class ImportCanceledModal extends React.Component {

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
  };

  render() {
    const { closeModal } = this.props;

    return <div className="cps-modal">
      <div className="cps-modal__screen"></div>
      <div className="cps-modal__dialog cps-card cps-card__height-3">
        <div className="cps-card__header cps-subheader-sm"><span>Import discarded</span>
          <a onClick={closeModal} className="cps-modal__dialog__close cps-icon cps-icon-close"></a>
        </div>
        <div className="cps-card__body">
          <p>
            It looks like this import was discarded. If you would like to start a new import, click on the menu and select "import contacts".
          </p>
        </div>
        <div className="cps-modal__dialog__actions">
          <button onClick={closeModal} className="cps-btn +primary">Okay</button>
        </div>
      </div>
    </div>
  }
}
