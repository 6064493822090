import React from 'react';
import { DateTime } from 'luxon'

export const non_display_columns = [
  'first_name',
  'middle_initial',
  'last_name',
  'name',
  'business_name',
  'is_business',
];

export const non_display_columns_for_resolved_row = [
  'name',
  'is_business',
];

export const DISPLAY_STATES = {
  COMPLETE: 'complete',
  DEDUPE: 'dedupe',
  IMPORT_ALREADY_COMPLETE: 'import-already-complete',
  IMPORT_CANCELED: 'import-canceled',
  LOADING: 'loading',
  UPDATING_CONTACT_LIST: 'updating-contact-list',
}

export function formatData(data, format, isBusiness) {
  if (!data) {
    return '';
  }
  switch (format) {
    case 'timestamp':
      return DateTime.fromMillis(parseInt(data, 10)).toLocaleString(DateTime.DATE_MED);
    case 'tin':
      return isBusiness ? formatEIN(data) : formatSSN(data);
    case 'phone':
      return formatPhoneNumber(data);
    default:
      return data.toString();
  }
}

function formatPhoneNumber(phoneNumber) {
  //Remove non-numeric characters
  const s2 = ("" + phoneNumber).replace(/\D/g, '');
  //10 digit format
  const matches = s2.match(/^(\d{3})(\d{3})(\d{4})$/);
  return !matches ? phoneNumber : `(${matches[1]})${matches[2]}-${matches[3]}`;
}

function formatSSN(ssn) {
  //Remove non-numeric characters
  const s2 = ("" + ssn).replace(/\D/g, '');
  //9 digit SSN format
  const matches = s2.match(/^(\d{3})(\d{2})(\d{4})$/);
  return !matches ? ssn : `${matches[1]}-${matches[2]}-${matches[3]}`;
}

function formatEIN(ein) {
  //Remove non-numeric characters
  const s2 = ("" + ein).replace(/\D/g, '');
  //9 digit EIN format
  const matches = s2.match(/^(\d{2})(\d{7})$/);
  return !matches ? ein : `${matches[1]}-${matches[2]}`;
}
