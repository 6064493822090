import React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { infoToast } from "toast-service!sofe";
import AsyncDecorator from 'async-decorator/rx6';
import { EMPTY, forkJoin } from 'rxjs';
import { count, skipWhile, take } from 'rxjs/operators'
import { retryBackoff } from 'backoff-rxjs';
import { catchAsyncStacktrace } from 'auto-trace';
import canopyUrls from 'canopy-urls!sofe';

import styles from './dedupe-modal.styles.css';
import PageTitleBar from './page-title-bar/page-title-bar.component.js';
import MergeCardGroup from './merge-card-group/merge-card-group.component.js';
import RejectedRowsDownloadModal from './modals/rejected-rows-download-modal.component.js';
import ImportCanceledModal from './modals/import-canceled-modal.component.js';
import ImportAlreadyFinishedModal from './modals/import-already-finished-modal.component.js';
import UpdatingContactListModal from './modals/updating-contact-list-modal.component.js';
import { getCRMColumns, getImportDetails, getLatestImports, getDupes, putResolutions } from './dedupe.resource.js';
import { cleanMapping, getCombinedColumnHeaderNames, getFilteredCRMColumns, sortConflictsByImportRow, getResolutionIndex, hasResolutionForCanopyId, getUnresolvedConflicts } from './dedupe-modal.helper.js';
import { DISPLAY_STATES } from './common/common.helper.js';
import {CprLoader} from 'canopy-styleguide!sofe';

@AsyncDecorator
class DataDeDupeModal extends React.Component {

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    context: PropTypes.object,
    id: PropTypes.string.isRequired, // Note the default input parameter in the showDedupeModal method
    invalid_rows: PropTypes.string,
  };

  constructor(props) {
    super(props);

    this.state = {
      displayState: props.displayState || DISPLAY_STATES.LOADING,
      crmColumns: [],
      resolutions: [],
      conflicts: [],
      id: props.id,
      viewportWidth: 1000,
    };
  }

  componentDidMount() {
    const { conflicts, id } = this.state;
    const { cancelWhenUnmounted, invalid_rows } = this.props;

    // no need to do unneeded work if the instantiation is for the purpose of getting the invalid_rows CSV
    if (invalid_rows) { return; }

    this.setImportIdAndDupes(id);

    //Sort conflicts by ascending import row value
    this.setState({ conflicts: sortConflictsByImportRow(conflicts) });

    cancelWhenUnmounted(
      getCRMColumns().subscribe(
        response => {
          const rawCrmColumns = response.fields;

          cancelWhenUnmounted(
            getImportDetails(id).subscribe(
              response => {
                const mapping = cleanMapping(response.imports.fields);

                // get the filtered set of CRMColumns that matches the merge_card_header_names array
                const crmColumns = getFilteredCRMColumns(rawCrmColumns, getCombinedColumnHeaderNames(mapping));

                this.setState({ crmColumns });
              },
              catchAsyncStacktrace()
            )
          );
        },
        catchAsyncStacktrace()
      )
    );

    this.calculateViewportWidth();
    window.addEventListener('resize', this.calculateViewportWidth);

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.calculateViewportWidth);
  }

  render() {
    const { closeModal, invalid_rows } = this.props;
    const { crmColumns, resolutions, conflicts, displayState, id, viewportWidth } = this.state;

    return invalid_rows
      ? <RejectedRowsDownloadModal closeModal={closeModal} import_id={id} />
      : displayState && ~[DISPLAY_STATES.LOADING, DISPLAY_STATES.IMPORT_CANCELED, DISPLAY_STATES.IMPORT_ALREADY_COMPLETE, DISPLAY_STATES.UPDATING_CONTACT_LIST].findIndex(state => state === displayState)
        ? this.displayModal(displayState)
        : <div className={`${styles.modalContent}`}>
          <PageTitleBar displayState={displayState} finishImport={this.finishImport} closeModal={closeModal} />
          <div className={`${styles.mainContent}`}>
            {
              conflicts.length
                ? <div className="cps-subheader-sm">
                  <span className="cps-light-gray">Duplicate contacts ({getUnresolvedConflicts(resolutions, conflicts).length})</span>
                  {
                    conflicts.map((duplicate, position) =>
                      <MergeCardGroup
                        key={position}
                        crmColumns={crmColumns}
                        import_row={duplicate.import_row}
                        conflicting_canopy_contacts={duplicate.conflicting_canopy_contacts}
                        resolutions={resolutions}
                        addResolution={this.addResolution}
                        deleteResolution={this.deleteResolution}
                        hasResolutionForCanopyId={hasResolutionForCanopyId.bind(this, resolutions)}
                        viewportWidth={viewportWidth}
                      />
                    )
                  }
                </div>
                : <div className={`${styles.fullHeight}`} style={{ justifyContent: 'center', alignItems: 'center' }}>
                  <CprLoader page="true"/>
                </div>
            }
          </div>
        </div>
  }

  calculateViewportWidth = () => {
    const w = window,
      d = document,
      documentElement = d.documentElement,
      body = d.getElementsByTagName('body')[0],
      viewportWidth = w.innerWidth || documentElement.clientWidth || body.clientWidth;

    this.setState({ viewportWidth });
  }

  displayModal = (displayState) => {
    const { closeModal } = this.props;
    switch (displayState) {
      case DISPLAY_STATES.IMPORT_CANCELED:
        return <ImportCanceledModal closeModal={closeModal} />
      case DISPLAY_STATES.IMPORT_ALREADY_COMPLETE:
        return <ImportAlreadyFinishedModal closeModal={closeModal} />
      case DISPLAY_STATES.UPDATING_CONTACT_LIST:
        return <UpdatingContactListModal />
      default:
        return null;
    }
  }

  setDisplayState = (displayState) => {
    this.setState({ displayState });
  }
  getDisplayState = () => {
    return this.state.displayState;
  }

  setImportIdAndDupes(import_id) {
    const { cancelWhenUnmounted } = this.props;

    const getImportStatus = import_id ? getImportDetails(import_id) : EMPTY;
    cancelWhenUnmounted(
      forkJoin(getImportStatus, getLatestImports()).subscribe(
        responses => {
          if (import_id && responses[0].imports && responses[0].imports.status === "done") {
            //The import is already complete
            this.setState({ displayState: DISPLAY_STATES.IMPORT_ALREADY_COMPLETE });
          } else {
            //Look at the most recent import
            const lastImport = responses[1].imports[0];
            if (import_id && lastImport.id !== import_id) {
              //A newer import exists
              this.setState({ displayState: DISPLAY_STATES.IMPORT_CANCELED });
            } else if (lastImport.status === "done") {
              //The latest import is complete
              this.setState({ displayState: DISPLAY_STATES.IMPORT_ALREADY_COMPLETE });
            } else if (lastImport.status === "awaiting_merge") {
              //The import needs to be resolved
              const id = lastImport.id;
              this.setState({ id, displayState: DISPLAY_STATES.DEDUPE });
              this.setDupes(id);
            } else {
              throw new Error("No pending imports to resolve");
            }
          }
        },
        catchAsyncStacktrace()
      )
    );
  }

  setDupes(id) {
    const { cancelWhenUnmounted } = this.props;
    cancelWhenUnmounted(
      getDupes(id).subscribe(
        response => {
          this.setState({ conflicts: sortConflictsByImportRow(response.import_dupes.duplicates) });
        }
      )
    );
  }

  addResolution = (contact) => {
    let { resolutions } = this.state;
    const indexOfResolution = getResolutionIndex(resolutions, contact.row_id);

    if (~indexOfResolution) {
      //Update resolution
      resolutions[indexOfResolution] = contact;
    } else {
      //Add new resolution
      resolutions.push(contact);
    }
    this.checkIsValid();
    this.setState({ resolutions });
  }

  deleteResolution = (row_id) => {
    let { resolutions } = this.state;
    const indexOfResolution = getResolutionIndex(resolutions, row_id);

    if (~indexOfResolution) {
      resolutions.splice(indexOfResolution, 1);
    }
    this.checkIsValid();
    this.setState({ resolutions });

    this.calculateViewportWidth();
  }

  checkIsValid = () => {
    const { resolutions, conflicts } = this.state;
    const displayState = getUnresolvedConflicts(resolutions, conflicts).length === 0 ? DISPLAY_STATES.COMPLETE : DISPLAY_STATES.DEDUPE;

    if (this.state.displayState !== displayState) {
      this.setState({ displayState });
    }
  }

  finishImport = () => {
    const { id, resolutions } = this.state;
    const { cancelWhenUnmounted } = this.props;

    this.setState({ displayState: DISPLAY_STATES.UPDATING_CONTACT_LIST });

    cancelWhenUnmounted(
      putResolutions(id, resolutions).subscribe(
        response => {
          this.pollServerForImportSuccess();
        },
        catchAsyncStacktrace()
      )
    );
  }

  pollServerForImportSuccess = () => {
    const { closeModal, cancelWhenUnmounted } = this.props;
    const { id } = this.state;

    cancelWhenUnmounted(
      //polling for import success with exponential back off. Gives up after 45 seconds
      getImportDetails(id).pipe(  //Call getImportDetails() each time
        skipWhile(res => res.imports.status !== "done"),  //ignore all statuses that aren't done
        take(1),  //complete the sequence after the first done request
        count(),  //count the number of values in the sequence, 0 means the import didn't finish in time
        retryBackoff({initialInterval: 1000, maxRetries: 4, maxInterval: 10000})
      )
      .subscribe(
        count => {
          canopyUrls.navigateToUrl('/#/clients');   //Ensure that we are on the right url
          if (count > 0){
            //A positive value means the import completed
            //TODO fire an event for the contact dashboard to refresh
            location.reload();
          } else {
            infoToast("Looks like your import is taking a while... We'll email you when it is complete", "Dismiss", () => {}, 600000)
          }
          closeModal();
        },
        catchAsyncStacktrace(),
      )
    )
  }
}

export default function showDedupeModal({ id = 0, invalid_rows = null }) {
  return new Promise((resolve, reject) => {
    const div = document.createElement('div');
    document.body.appendChild(div);

    ReactDOM.render(<DataDeDupeModal id={id} invalid_rows={invalid_rows} closeModal={() => {
      ReactDOM.unmountComponentAtNode(div);
      div.parentNode.removeChild(div);

      resolve();
    }} />, div);
  });
}
