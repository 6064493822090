import React from 'react';
import PropTypes from 'prop-types';

export default class ExitConfirmationModal extends React.Component {

  static propTypes = {
    closeModal: PropTypes.func.isRequired,
    closeDialog: PropTypes.func.isRequired,
  };

  render() {
    const { closeModal, closeDialog } = this.props;

    return <div className="cps-modal">
      <div className="cps-modal__screen"></div>
      <div className="cps-modal__dialog cps-card cps-card__height-3" style={{ textAlign: 'left' }}>
        <div className="cps-card__header cps-subheader-sm">
          <span>Are you sure you want to exit?</span>
          <a className="cps-modal__dialog__close cps-icon cps-icon-close" onClick={closeDialog}></a>
        </div>
        <div className="cps-card__body">
          <p style={{ fontWeight: 'bold' }}>
            You haven't handled all possible duplicates yet.
          </p>
          <p>
            If you leave now, your contacts will not be merged. You will be able to finish this process by selecting <span style={{ fontWeight: 'bold' }}>"import contacts"</span> from the Contacts menu.
          </p>
          <p style={{ fontStyle: 'italic' }}>
            Any contacts that you have already merged will need to be merged again.
          </p>
        </div>
        <div className="cps-modal__dialog__actions" style={{ textAlign: 'initial' }}>
          <button className="cps-btn +primary" onClick={closeModal}>Abandon changes and exit</button>
          <a className="cps-link" onClick={closeDialog}>Cancel</a>
        </div>
      </div>
    </div>
  }
}
