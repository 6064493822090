exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-merge-card-merge-card-styles__mergeCard {\n  margin-top: 12px;\n  margin-bottom: 12px;\n  max-width: initial;\n  min-width: initial;\n}\n\n.src-merge-card-merge-card-styles__cardBody {\n  padding: 24px 20px;\n}\n\n.src-merge-card-merge-card-styles__actionStrip {\n  display: flex;\n  align-items: center;\n  justify-content: flex-start;\n  margin-top:26px;\n  display: inline-block;\n  width: 100%;\n}", ""]);

// exports
exports.locals = {
	"mergeCard": "src-merge-card-merge-card-styles__mergeCard",
	"cardBody": "src-merge-card-merge-card-styles__cardBody",
	"actionStrip": "src-merge-card-merge-card-styles__actionStrip"
};