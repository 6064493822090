import React from 'react';
import PropTypes from 'prop-types';
import styles from './table-container.styles.css';
import { buildFixedColumnArray } from './table-container.helper.js';
import { non_display_columns, formatData } from '../../common/common.helper.js';
import {CpTooltip} from 'canopy-styleguide!sofe'

export default class TableContainer extends React.PureComponent {

  static propTypes = {
    crmColumns: PropTypes.array.isRequired,
    matchingColumns: PropTypes.array.isRequired,
    duplicateSet: PropTypes.array.isRequired,
    viewportWidth: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);
    const fixedColumns = buildFixedColumnArray(this.props.duplicateSet);

    this.state = {
      fixedColumns: fixedColumns || [],
      tableWidth: 750,
    };
  }

  componentDidMount() {
    this.calculateTableWidth(this.props.viewportWidth);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.viewportWidth !== this.props.viewportWidth) {
      this.calculateTableWidth(prevProps.viewportWidth);
    }
  }

  render() {
    const { crmColumns, matchingColumns, duplicateSet } = this.props;
    const { fixedColumns, tableWidth } = this.state;

    return (
      <table className={`${styles.tableContainer}`} cellSpacing="0">
        <tbody>
          <tr>
            <td
              ref={(el) => {
                this.fixedColumnItemRef = el;
              }}
              style={{ verticalAlign: "top" }}
            >
              <table style={{ borderCollapse: "collapse" }} cellSpacing="0">
                <tbody>
                  <tr className={`${styles.tableRow} ${styles.heading}`}>
                    <td className={`cps-caption ${styles.fixedColumn}`}>
                      Name
                    </td>
                  </tr>
                  {fixedColumns.map((fixedColumnItem, position) => (
                    <tr
                      key={position}
                      className={`${styles.tableRow} ${styles.duplicate}`}
                    >
                      <td
                        className={`cps-caption ${styles.fixedColumn}`}
                        style={{ verticalAlign: "top" }}
                      >
                        <table
                          style={{ borderCollapse: "collapse" }}
                          cellSpacing="0"
                        >
                          <tbody>
                            <tr>
                              <td style={{ padding: "initial" }}>
                                {fixedColumnItem.name}
                              </td>
                              <td style={{ padding: "initial" }}>
                                {fixedColumnItem.isCanopy ? (
                                  <CpTooltip text="Contact already exists in Canopy">
                                    <img
                                      src="https://cdn.canopytax.com/images/canopy-logo-icon.svg"
                                      alt="Canopy record"
                                      className="cps-margin-8"
                                      style={{ borderRadius: 5 }}
                                    />
                                  </CpTooltip>
                                ) : Number.isInteger(
                                    fixedColumnItem.importRowId
                                  ) ? (
                                  <CpTooltip
                                    text={`Data from row ${
                                      fixedColumnItem.importRowId + 2
                                    } of import file`}
                                  >
                                    <div className={`${styles.rowBadge}`}>
                                      Row {fixedColumnItem.importRowId + 2}
                                    </div>
                                  </CpTooltip>
                                ) : null}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </td>
            <td
              rowSpan={fixedColumns.length}
              className={`${styles.scrollingCell}`}
            >
              <div style={{ width: `${tableWidth}px`, overflowX: "auto" }}>
                <table
                  style={{ borderCollapse: "collapse" }}
                  cellSpacing="0"
                  width="100%"
                >
                  <tbody>
                    <tr className={`${styles.tableRow} ${styles.heading}`}>
                      {crmColumns.map((column, position) => {
                        return !non_display_columns.includes(column.name) ? (
                          <td key={position} className="cps-caption">
                            <CpTooltip text={column.label}>
                              <div
                                className={
                                  matchingColumns.includes(column.name)
                                    ? styles.matchingColumn
                                    : ""
                                }
                              >
                                {column.label}
                              </div>
                            </CpTooltip>
                          </td>
                        ) : null;
                      })}
                    </tr>
                    {duplicateSet.map((duplicateSetItem, position) => {
                      return (
                        <tr
                          key={position}
                          className={`${styles.tableRow} ${styles.duplicate}`}
                          style={{ height: "57px" }}
                        >
                          {crmColumns.map((column, position2) => {
                            return !non_display_columns.includes(
                              column.name
                            ) ? (
                              <td
                                key={position2}
                                className={`cps-caption ${
                                  matchingColumns.includes(column.name)
                                    ? styles.matchingColumn
                                    : ""
                                }`}
                              >
                                {formatData(
                                  duplicateSetItem[column.name],
                                  column.type,
                                  duplicateSetItem.is_business
                                ) || <span>&mdash;&mdash;</span>}
                              </td>
                            ) : null;
                          })}
                        </tr>
                      );
                    })}
                  </tbody>
                </table>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }

  calculateTableWidth = (viewportWidth) => {
    if (this.fixedColumnItemRef) {
      const tableWidth = viewportWidth - this.fixedColumnItemRef.clientWidth - 20 * 2 - 40 * 2 - 10 * 2;
      this.setState({ tableWidth });
    }
  }
}
