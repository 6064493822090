exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-merge-card-group-merge-card-group-styles__duplicate {\n  margin: 0px 16px 0px 16px;\n}\n\n.src-merge-card-group-merge-card-group-styles__duplicateSet {\n  background: #EAEBEF;\n  padding: 1px 12px;\n  border-radius: 8px;\n  margin: 16px 4px 0 4px;\n}", ""]);

// exports
exports.locals = {
	"duplicate": "src-merge-card-group-merge-card-group-styles__duplicate",
	"duplicateSet": "src-merge-card-group-merge-card-group-styles__duplicateSet"
};