import React from 'react';
import PropTypes from 'prop-types';
import { resolution_actions } from '../dedupe-modal.helper.js';
import ResolvedConflictTable from './resolved-contact-table.component.js';
import styles from '../merge-card-group/merge-card-group.styles.css';

export default class ResolvedContact extends React.Component {

  static propTypes = {
    import_row: PropTypes.object.isRequired,
    crmColumns: PropTypes.array.isRequired,
    conflicting_canopy_contacts: PropTypes.array.isRequired,
    resolutions: PropTypes.array.isRequired,
    deleteResolution: PropTypes.func.isRequired,
  };

  render() {
    const {import_row, crmColumns, conflicting_canopy_contacts, resolutions, deleteResolution} = this.props;
    const {resolution, merged, keptSeparate} = this.sortRows(resolutions, import_row, conflicting_canopy_contacts);

    if (resolution.action === resolution_actions.merge && keptSeparate.length > 0) {
      //Show multiple tables when 1 Canopy Contact Merged and 1+ Kept Separate
      return <div>
        <div>
          <ResolvedConflictTable
            title={'Merged'}
            crmColumns={crmColumns}
            rowsToDisplay={[merged]}
            deleteResolution={deleteResolution.bind(this, merged.row_id)}
          />
        </div>
        <div>
          <ResolvedConflictTable
            title={'Kept separate'}
            crmColumns={crmColumns}
            rowsToDisplay={keptSeparate}
            deleteResolution={deleteResolution.bind(this, merged.row_id)}
          />
        </div>
      </div>
    } else {
      //Normal case: single table of either merged or separate contacts
      return <div>
        <ResolvedConflictTable
          title={merged.action === resolution_actions.merge ? 'Merged' : 'Kept separate'}
          crmColumns={crmColumns}
          rowsToDisplay={[merged, ...keptSeparate]}
          deleteResolution={deleteResolution.bind(this, merged.row_id)}
        />
      </div>
    }
  }

  sortRows(resolutions, import_row, conflicting_canopy_contacts){
    //Get the resolution object
    const indexOfResolution = resolutions.findIndex(resolution => resolution.row_id === import_row.row_id);
    const resolution = resolutions[indexOfResolution];

    //Get the mergedCanopyContact (if it exists)
    const indexOfMergedCanopyContact = conflicting_canopy_contacts.findIndex(contact => resolution && contact.id === resolution.id);
    const mergedCanopyContact = ~indexOfMergedCanopyContact ? conflicting_canopy_contacts[indexOfMergedCanopyContact] : {};

    //Merge the mergedCanopyContact and resolution values (for display)
    const merged = {...mergedCanopyContact, ...resolution};
    //Get the canopyContacts that were kept separate
    const keptSeparate = conflicting_canopy_contacts.filter(contact => contact.id !== merged.id);

    return {resolution, merged, keptSeparate}
  }

}
