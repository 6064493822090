import React from 'react';
import PropTypes from 'prop-types';
import { lookUpColumnLabel, lookUpColumnDataType } from '../dedupe-modal.helper.js';
import { mergeContacts, determineConflicts } from './merge-card.helper.js';
import TableContainer from './table-container/table-container.component.js';
import ResolveConflictModal from '../modals/resolve-conflict-modal.component.js';
import styles from './merge-card.styles.css';

export default class MergeCard extends React.Component {

  static propTypes = {
    crmColumns: PropTypes.array.isRequired,
    duplicateSet: PropTypes.array.isRequired,
    matchingColumns: PropTypes.array.isRequired,
    conflictingColumns: PropTypes.array.isRequired,
    resolveConflicts: PropTypes.func.isRequired,
    keepSeparate: PropTypes.func.isRequired,
    viewportWidth: PropTypes.number.isRequired,
  };

  constructor(props) {
    super(props);

    this.state = {
      showConflictModal: false,
      showSsnModal: false,
    }
  }

  render() {
    const { crmColumns, duplicateSet, matchingColumns, conflictingColumns, keepSeparate, resolveConflicts, viewportWidth } = this.props;
    const { showConflictModal, showSsnModal } = this.state;

    const conflicts = determineConflicts(duplicateSet, conflictingColumns, crmColumns);

    return <div>
      {
        showConflictModal && <ResolveConflictModal
          conflicts={conflicts}
          resolveConflicts={this.resolveConflicts.bind(this, duplicateSet)}
          crmColumns={crmColumns}
          closeDialog={() => this.setState({ showConflictModal: false })}
          isBusiness={duplicateSet[0].is_business}
        />
      }
      <div className={`cps-flexible-focus cps-card ${styles.mergeCard}`}>
        <div className={`cps-card__body ${styles.cardBody}`}>
          <TableContainer
            crmColumns={crmColumns}
            matchingColumns={matchingColumns}
            duplicateSet={duplicateSet}
            viewportWidth={viewportWidth}
          />
          <div className={styles.actionStrip}>
            <button onClick={() => this.setState({ showConflictModal: true })} className='cps-btn +primary' >Merge</button>
            <a onClick={keepSeparate.bind(null, true)} className='cps-link' >Keep separate</a>
          </div>
        </div>
      </div>
    </div>
  }

  resolveConflicts = (duplicateSet, mergeChoices) => {
    this.props.resolveConflicts(mergeContacts(duplicateSet, mergeChoices));
  }
}
