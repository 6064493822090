import React from 'react';
import {CprLoader} from 'canopy-styleguide!sofe';

export default class UpdatingContactListModal extends React.Component {
  render() {
    return <div className="cps-modal">
      <div className="cps-modal__screen cps-bg-dark-gray" style={{ opacity:'.75' }}></div>
      <div className="cps-modal__dialog" style={{ boxShadow:'none' }}>
        <div style={{ marginLeft: '23%', height:'48px' }}>
          <CprLoader page="true"/>
        </div>
        <div className="cps-subheader-sm cps-margin-top-16" style={{ color: 'white', textAlign: 'center' }}>
          We are updating your contacts. Thanks for your patience...
        </div>
      </div>
    </div>
  }
}
