exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-resolved-contact-resolved-contact-table-styles__resolvedTable {\n  border-collapse: collapse;\n  width: 100%;\n  border: thin solid lightgray;\n  white-space: nowrap;\n}\n\n.src-resolved-contact-resolved-contact-table-styles__tableSpacing {\n  margin: 16px 0 24px 0;\n  white-space: nowrap;\n}\n\n.src-resolved-contact-resolved-contact-table-styles__borderBottom {\n  border-bottom: thin solid lightgray;\n}\n\n.src-resolved-contact-resolved-contact-table-styles__tableHead {\n  height: 32px;\n  border-bottom: thin solid lightgray;\n}\n.src-resolved-contact-resolved-contact-table-styles__tableBody {\n  height: 72px;\n  border-bottom: thin solid lightgray;\n}", ""]);

// exports
exports.locals = {
	"resolvedTable": "src-resolved-contact-resolved-contact-table-styles__resolvedTable",
	"tableSpacing": "src-resolved-contact-resolved-contact-table-styles__tableSpacing",
	"borderBottom": "src-resolved-contact-resolved-contact-table-styles__borderBottom",
	"tableHead": "src-resolved-contact-resolved-contact-table-styles__tableHead",
	"tableBody": "src-resolved-contact-resolved-contact-table-styles__tableBody"
};