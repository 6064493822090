exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".src-merge-card-table-container-table-container-styles__tableContainer {\n  border: 1px solid var(--cp-color-table-border);\n  border-radius: 5px;\n  max-width: 1024px;\n}\n\n.src-merge-card-table-container-table-container-styles__tableRow {\n  border-radius: initial;\n  border-bottom: 1px solid var(--cp-color-table-border);\n  vertical-align: middle;\n  background-color: var(--cp-color-table-bg);\n}\n\n.src-merge-card-table-container-table-container-styles__fixedColumn {\n  width: 150px;\n  background-color: var(--cp-color-table-bg);\n  font-weight: bold;\n  color: var(--cp-color-app-secondary-text);\n  border-right: 1px solid var(--cp-color-table-border);\n}\n\n.src-merge-card-table-container-table-container-styles__tableRow td {\n  padding: 0 16px;\n  white-space: nowrap;\n}\n\n.src-merge-card-table-container-table-container-styles__tableRow.src-merge-card-table-container-table-container-styles__heading td {\n  height: 32px;\n  color: var(--cp-color-app-secondary-text);\n}\n\n.src-merge-card-table-container-table-container-styles__tableRow.src-merge-card-table-container-table-container-styles__duplicate td {\n  height: 56px;\n  color: var(--cp-color-app-secondary-text);\n}\n\n.src-merge-card-table-container-table-container-styles__tableRow:last-of-type {\n  border-bottom: initial !important;\n}\n\n.src-merge-card-table-container-table-container-styles__rowBadge {\n  margin-left: 8px;\n  background: lightgray;\n  padding: 0px 4px 0px 4px;\n  font-size: smaller;\n  font-weight: normal;\n  cursor: default;\n  border-radius: 5px;\n}\n\n.src-merge-card-table-container-table-container-styles__scrollingCell {\n  vertical-align: top;\n  background-color: var(--cp-color-table-bg);\n}\n\n.src-merge-card-table-container-table-container-styles__matchingColumn {\n  font-weight: bold;\n  color: var(--cp-color-app-secondary-text);\n}\n", ""]);

// exports
exports.locals = {
	"tableContainer": "src-merge-card-table-container-table-container-styles__tableContainer",
	"tableRow": "src-merge-card-table-container-table-container-styles__tableRow",
	"fixedColumn": "src-merge-card-table-container-table-container-styles__fixedColumn",
	"heading": "src-merge-card-table-container-table-container-styles__heading",
	"duplicate": "src-merge-card-table-container-table-container-styles__duplicate",
	"rowBadge": "src-merge-card-table-container-table-container-styles__rowBadge",
	"scrollingCell": "src-merge-card-table-container-table-container-styles__scrollingCell",
	"matchingColumn": "src-merge-card-table-container-table-container-styles__matchingColumn"
};